/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSeat = /* GraphQL */ `
  query GetSeat($id: ID!) {
    getSeat(id: $id) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
    }
  }
`;
export const listSeats = /* GraphQL */ `
  query ListSeats(
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const seatsByDepartmentID = /* GraphQL */ `
  query SeatsByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatsByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const seatsByRotationIDAndDepartmentID = /* GraphQL */ `
  query SeatsByRotationIDAndDepartmentID(
    $rotationID: ID!
    $departmentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatsByRotationIDAndDepartmentID(
      rotationID: $rotationID
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const seatsByTraineeIDAndRotationID = /* GraphQL */ `
  query SeatsByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatsByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPublishedSeat = /* GraphQL */ `
  query GetPublishedSeat($id: ID!) {
    getPublishedSeat(id: $id) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
    }
  }
`;
export const listPublishedSeats = /* GraphQL */ `
  query ListPublishedSeats(
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishedSeats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publishedSeatsByDepartmentID = /* GraphQL */ `
  query PublishedSeatsByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedSeatsByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publishedSeatsByRotationIDAndDepartmentID = /* GraphQL */ `
  query PublishedSeatsByRotationIDAndDepartmentID(
    $rotationID: ID!
    $departmentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedSeatsByRotationIDAndDepartmentID(
      rotationID: $rotationID
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publishedSeatsByTraineeIDAndRotationID = /* GraphQL */ `
  query PublishedSeatsByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedSeatsByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrainee = /* GraphQL */ `
  query GetTrainee($id: String!) {
    getTrainee(id: $id) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      manualOverrideID
      manualOverride {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      traineeDiary
      createdAt
      updatedAt
    }
  }
`;
export const listTrainees = /* GraphQL */ `
  query ListTrainees(
    $id: String
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          cohortsIndex
          averagePreference
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          createdAt
          updatedAt
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          createdAt
          updatedAt
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          createdAt
          updatedAt
        }
        traineeDiary
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const traineesByTypeAndName = /* GraphQL */ `
  query TraineesByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          cohortsIndex
          averagePreference
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          createdAt
          updatedAt
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          createdAt
          updatedAt
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          createdAt
          updatedAt
        }
        traineeDiary
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const traineesByCohortIDAndName = /* GraphQL */ `
  query TraineesByCohortIDAndName(
    $cohortID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByCohortIDAndName(
      cohortID: $cohortID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          cohortsIndex
          averagePreference
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          createdAt
          updatedAt
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          createdAt
          updatedAt
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
            }
            nextToken
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          createdAt
          updatedAt
        }
        traineeDiary
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPreference = /* GraphQL */ `
  query GetPreference($id: ID!) {
    getPreference(id: $id) {
      id
      departmentID
      rotationID
      priority
      traineeID
      createdAt
      updatedAt
    }
  }
`;
export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preferencesByDepartmentID = /* GraphQL */ `
  query PreferencesByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preferencesByRotationID = /* GraphQL */ `
  query PreferencesByRotationID(
    $rotationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByRotationID(
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preferencesByTraineeIDAndRotationID = /* GraphQL */ `
  query PreferencesByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      type
      name
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      departmentInfo
      litigationDepartment
      bankingDepartment
      createdAt
      updatedAt
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const departmentsByIdAndName = /* GraphQL */ `
  query DepartmentsByIdAndName(
    $id: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByIdAndName(
      id: $id
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const departmentsByTypeAndName = /* GraphQL */ `
  query DepartmentsByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRotation = /* GraphQL */ `
  query GetRotation($id: ID!) {
    getRotation(id: $id) {
      id
      type
      startDate
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      cohortsIndex
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfNoPreferences
      numberOfManualOverrides
      published
      createdAt
      updatedAt
    }
  }
`;
export const listRotations = /* GraphQL */ `
  query ListRotations(
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rotationsByIdAndStartDate = /* GraphQL */ `
  query RotationsByIdAndStartDate(
    $id: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByIdAndStartDate(
      id: $id
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rotationsByTypeAndStartDate = /* GraphQL */ `
  query RotationsByTypeAndStartDate(
    $type: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByTypeAndStartDate(
      type: $type
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDepartmentStatistic = /* GraphQL */ `
  query GetDepartmentStatistic($id: ID!) {
    getDepartmentStatistic(id: $id) {
      id
      rotationID
      departmentID
      departmentName
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFirstPreferencesAssigned
      numberOfSecondPreferencesAssigned
      numberOfThirdPreferencesAssigned
      numberOfFourthPreferencesAssigned
      numberOfNoPreferencesAssigned
      numberOfManualOverrides
      numberOfSeatsAssigned
      numberOfSeatsAvailable
      numberOfFirstYearsAssigned
      numberOfSecondYearsAssigned
      createdAt
      updatedAt
    }
  }
`;
export const listDepartmentStatistics = /* GraphQL */ `
  query ListDepartmentStatistics(
    $filter: ModelDepartmentStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rotationID
        departmentID
        departmentName
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFirstPreferencesAssigned
        numberOfSecondPreferencesAssigned
        numberOfThirdPreferencesAssigned
        numberOfFourthPreferencesAssigned
        numberOfNoPreferencesAssigned
        numberOfManualOverrides
        numberOfSeatsAssigned
        numberOfSeatsAvailable
        numberOfFirstYearsAssigned
        numberOfSecondYearsAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const departmentStatisticsByRotationIDAndDepartmentName = /* GraphQL */ `
  query DepartmentStatisticsByRotationIDAndDepartmentName(
    $rotationID: ID!
    $departmentName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentStatisticsByRotationIDAndDepartmentName(
      rotationID: $rotationID
      departmentName: $departmentName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rotationID
        departmentID
        departmentName
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFirstPreferencesAssigned
        numberOfSecondPreferencesAssigned
        numberOfThirdPreferencesAssigned
        numberOfFourthPreferencesAssigned
        numberOfNoPreferencesAssigned
        numberOfManualOverrides
        numberOfSeatsAssigned
        numberOfSeatsAvailable
        numberOfFirstYearsAssigned
        numberOfSecondYearsAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
