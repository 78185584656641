import '@aws-amplify/ui-react/styles.css';
import {
    Show,
    SimpleShowLayout,
    ReferenceInput,
    SelectInput,
    useGetOne,
    ReferenceManyField,
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    TextInput,FunctionField,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    Labeled,
    ShowButton,
    usePermissions,
    useRecordContext,
    useListContext,
    WrapperField,
    RichTextField,
    BooleanInput,
    useRedirect,
    useGetList,
    SimpleList,
    InfiniteList
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import { Typography, Chip, TableCell ,TableRow, TableHead, useMediaQuery } from '@mui/material';
import {PostPagination} from './Trainee';
import {BackButton} from './BackButton';

export const DepartmentListHeader = ({permissions,totalSeatsAvailable,numberOfSeatsReservedForFirstYears, numberOfSeatsReservedForSecondYears}) => (
    <TableHead >
        <TableRow>
        {permissions?.includes("Admins") && <TableCell ></TableCell>}
        <TableCell key={"name"}>
            <Typography sx={{fontWeight: 'bold', fontSize: 14}}>{"Department"}</Typography>
        </TableCell>
        <TableCell key={"numberOfSeatsAvailable"}>
            <Typography sx={{ textAlign:'center', fontWeight: 'bold', fontSize: 14}}>{"Number of seats available"}</Typography>
            <Typography sx={{ textAlign:'center', fontWeight: 'bold', fontSize: 14}}>{"Total: " + totalSeatsAvailable} </Typography>
        </TableCell>
        <TableCell key={"numberOfSeatsReservedForFirstYears"}>
        <Typography sx={{ textAlign:'center', fontWeight: 'bold', fontSize: 14}}>{"Number of seats reserved for first years"}</Typography>
            <Typography sx={{ textAlign:'center', fontWeight: 'bold', fontSize: 14}}>{"Total: " + numberOfSeatsReservedForFirstYears} </Typography>
        </TableCell>
        <TableCell key={"numberOfSeatsReservedForSecondYears"}>
        <Typography sx={{ textAlign:'center', fontWeight: 'bold', fontSize: 14}}>{"Number of seats reserved for second years"}</Typography>
            <Typography sx={{ textAlign:'center', fontWeight: 'bold', fontSize: 14}}>{"Total: " + numberOfSeatsReservedForSecondYears} </Typography>        
        </TableCell>
        <TableCell >
            
        </TableCell>
        <TableCell >
            
        </TableCell>
        </TableRow>
    </TableHead>
)


export const DepartmentsList = (props) => {
    const { permissions } = usePermissions();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    const departmentsList = useGetList('departments', {filter:{ departmentsByTypeAndName: { type: "Department" } }});
    var totalSeatsAvailable = 0;
    var numberOfSeatsReservedForFirstYears = 0
    var numberOfSeatsReservedForSecondYears = 0
    for (const department in departmentsList.data){
        totalSeatsAvailable += departmentsList.data[department].numberOfSeatsAvailable
        numberOfSeatsReservedForFirstYears += departmentsList.data[department].numberOfSeatsReservedForFirstYears
        numberOfSeatsReservedForSecondYears += departmentsList.data[department].numberOfSeatsReservedForSecondYears
    }
    if (!departmentsList.isLoading){
        if(isSmall){
        return (<Labeled label="Departments">
        <InfiniteList {...props} exporter={false}sort={{ field: 'name', order: 'DESC' }} filter={{ departmentsByTypeAndName: { type: "Department" } }} actions={false}>
                <SimpleList primaryText={record =>record.name}  secondaryText={record => record.numberOfSeatsAvailable != 1 ? record.numberOfSeatsAvailable + " seats available" : "1 seat available"} linkType="show"/>
            </InfiniteList></Labeled>)
        } else {
        return (
            
            <List {...props} exporter={false} pagination={<PostPagination/>} sort={{ field: 'name', order: 'DESC' }} filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                <Datagrid header={<DepartmentListHeader permissions={permissions} totalSeatsAvailable={totalSeatsAvailable} numberOfSeatsReservedForFirstYears={numberOfSeatsReservedForFirstYears} numberOfSeatsReservedForSecondYears={numberOfSeatsReservedForSecondYears} />} bulkActionButtons={permissions?.includes("Admins") ? true : false} sx={{
                    '& .column-name': { textAlign: 'left' },
                }}>
                    <TextField source="name" sortBy="departmentsByName" sortable={false} />
                    <NumberField source="numberOfSeatsAvailable"  sortable={false} />
                    <NumberField source="numberOfSeatsReservedForFirstYears" sortable={false} />
                    <NumberField source="numberOfSeatsReservedForSecondYears" sortable={false} />
                    {permissions?.includes("Admins") && (<EditButton />)}
                    <WrapperField><ShowButton label="More details"/></WrapperField>
                </Datagrid>
            </List>
        );}
        }
};

export const DepartmentsCreate = (props) => (
    <Create {...props} actions={<BackButton/>} >
        <SimpleForm defaultValues={{ type: "Department" }} >
            <TextInput source="name" />
            <BooleanInput label="Litigation department" source="litigationDepartment"/>
            <BooleanInput label="Corporate department" source="bankingDepartment"/>
            <NumberInput source="numberOfSeatsAvailable" />
            <NumberInput source="numberOfSeatsReservedForFirstYears" defaultValue={0} />
            <NumberInput source="numberOfSeatsReservedForSecondYears" defaultValue={0} />
        </SimpleForm>
    </Create>
);

export const DepartmentsEdit = (props) => (
    <Edit {...props} actions={<BackButton/>}>
        <SimpleForm >
            <TextInput source="name" />
            <NumberInput source="numberOfSeatsAvailable" />
            <NumberInput source="numberOfSeatsReservedForFirstYears" />
            <NumberInput source="numberOfSeatsReservedForSecondYears" />
            <BooleanInput  label="Litigation department" source="litigationDepartment"/>
            <BooleanInput label="Corporate department" source="bankingDepartment"/>
            <RichTextInput source="departmentInfo" label="Department Information:"/>
        </SimpleForm>
    </Edit>
);

export const DepartmentShow = (props) => {
    const {permissions}= usePermissions();
    return (
        <Show {...props} actions={<BackButton/>}>
        <SimpleShowLayout>
            <TextField source="name" label="Name"/>
            <RichTextField source="departmentInfo" label="Department Information"/>
            {permissions?.includes("Admins") && 
            <DepartmentSeatsList />}
        </SimpleShowLayout>
    </Show>
    )
}

const validateStartDate = (values) => {
    const errors = {};

    if (!values) {
        errors.rotation = "Must select a rotation"
    }
    return errors
}

const DepartmentSeatsFilter = [
    <ReferenceInput label="Rotation" reference="rotations" source="seatsByRotationIDAndDepartmentID.rotationID" alwaysOn defaultValue={""} filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }} >
        <SelectInput label="Rotation Date"  validate={validateStartDate}/>
    </ReferenceInput>
];

const TraineeByPriorityField = () => {
        const record = useRecordContext();
        const id = record.traineeID + "::" + record.traineeID;
        const redirect = useRedirect();
        const {isLoading, data} =  useGetOne('trainees', {id});
        const {filterValues} = useListContext();

        console.log('seat', data)
        const colors = ['#50C878', '#FAC898', '#E97451', '#11111f']
        var changeTextColour = false
        var priority = 4;
        for (const preference in data?.Preferences.items) {
            if ((data['Preferences']['items'][preference]['departmentID'] == record.departmentID)&&
                (data['Preferences']['items'][preference]['rotationID'] == filterValues['seatsByRotationIDAndDepartmentID']['rotationID'])) {
                    priority = data['Preferences']['items'][preference]['priority']
            }
        }
        if (priority) {
            if (priority >= 4) {
                priority = 4
                changeTextColour = true
            }
            if (changeTextColour) {
            return (<Chip label={data?.name}  sx={{ backgroundColor: colors[priority - 1], '& .MuiChip-label': {color: 'white'}}} onClick={()=> redirect(`/trainees/${data.id}/show`)} clickable={true}/>)
            } else {
                    return (<Chip label={data?.name}  sx={{ backgroundColor: colors[priority - 1], '& .MuiChip-label': {color: 'black'}}} onClick={()=> redirect(`/trainees/${data.id}/show`)} clickable={true}/>)
            }
        } else {
            return (
                    <Chip label={data?.name}  onClick={()=> redirect(`/trainees/${data.id}/show`)} clickable={true}/>
            )
        }
    
}

export const DepartmentSeatsList = () => {
    const record = useRecordContext();
    console.log("departmentSeats", record)
    return (
        <Labeled label="Assigned Trainees">
        <List resource="seats" filters={DepartmentSeatsFilter} actions={false} pagination={<PostPagination/>}  queryOptions={{ meta: {seatsByRotationIDAndDepartmentID: {departmentID: {"eq": record.id}}}}} empty={false} sx={{width: "150px"}}>
            <Datagrid bulkActionButtons={false}  >
            <TraineeByPriorityField/> 
            </Datagrid>
        </List>
        </Labeled>
    );
}