/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://e7da1ozuhg.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://2gghb7b24l.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://jyue5zsyt0.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://skafpaqlnzf4bpykupqb247eh4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:c566a5de-fc3b-460e-b35b-0e5b6259d100",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_fnnhB7YFU",
    "aws_user_pools_web_client_id": "1n85scjsef6684gjo1v2q9ld6i",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c185523-demo",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
