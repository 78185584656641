import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    NumberInput,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
    DateField,
    Show,
    SimpleShowLayout,
    required
} from "react-admin";
import {BackButton} from './BackButton';

const validatePreference = (values) => {
    const errors = {};
    console.warn("values",values)
    if (!values.departmentID[0]) {
        errors['departmentID.0'] = "Must enter a first preference"
    }
    if (!values.departmentID[1]) {
        errors['departmentID.1']  = "Must enter a second preference"
    }
    if (!values.departmentID[2]) {
        errors['departmentID.2']  = "Must enter a third preference"
    }
    if (!values.departmentID[3]) {
        errors['departmentID.3']  = "Must enter a fourth preference"
    }
    return errors
}
export const ManyPreferenceCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/');
        refresh();
    };

    const onError = onSuccess;

    return (
        <Create {...props} mutationOptions={{ onError, onSuccess, meta: { many: true } }}actions={<BackButton/>}>
            <SimpleForm validate={validatePreference}>
                <ReferenceInput label="1st Preference" source="departmentID.0" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                    <SelectInput optionText="name" label="1st Preference" />
                </ReferenceInput>
                <ReferenceInput label="2nd Preference" source="departmentID.1" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                    <SelectInput optionText="name" label="2nd Preference" />
                </ReferenceInput>
                <ReferenceInput label="3rd Preference" source="departmentID.2" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}> 
                    <SelectInput optionText="name" label="3rd Preference" />
                </ReferenceInput>
                <ReferenceInput label="4th Preference" source="departmentID.3" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}> 
                    <SelectInput optionText="name" label="4th Preference" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
}

export const PreferenceCreate = (props) => (
    <Create {...props} actions={<BackButton/>}>
        <SimpleForm>
            <ReferenceInput label="Department" source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                <SelectInput optionText="name" label="Department" />
            </ReferenceInput>
            <NumberInput source="priority" defaultValue="1" />
        </SimpleForm>
    </Create>
);

export const PreferenceEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();


    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/trainees');
        refresh();
    };
    return (
        <Edit {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<BackButton/>}>
            <SimpleForm>
                <ReferenceInput source="departmentID" reference="departments" filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                    <SelectInput optionText="name" label="Department" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>)
};

export const PreferenceShow = (props) => {
    return (
        <Show {...props} actions={<BackButton/>}>
            <SimpleShowLayout>
                <ReferenceField source="rotationID" reference="rotations" label="Rotation" />
                <ReferenceField source="departmentID" reference="departments" label="Department" />
                <NumberField source="priority" />
            </SimpleShowLayout>
        </Show>)
};




const PreferenceList = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="departmentID" reference="departments" label="Department">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="traineeID" reference="trainees" label="Trainee" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="priority" />
                <EditButton />
            </Datagrid>
        </List>
    );
};