export const departmentsByTypeAndName = /* GraphQL */ `
  query departmentsByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      type
      name
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      departmentInfo
      litigationDepartment
      bankingDepartment
      createdAt
      updatedAt
    }
  }
`;

export const getTrainee = /* GraphQL */ `
  query GetTrainee($id: String!) {
    getTrainee(id: $id) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        published
        createdAt
        updatedAt
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
      }
      traineeDiary
      createdAt
      updatedAt
    }
  }
`;

export const rotationsByTypeAndStartDate = /* GraphQL */ `
  query RotationsByTypeAndStartDate(
    $type: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByTypeAndStartDate(
      type: $type
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const rotationsByTypeAndStartDateAdmin = /* GraphQL */ `
  query RotationsByTypeAndStartDate(
    $type: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByTypeAndStartDate(
      type: $type
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getRotation = /* GraphQL */ `
  query GetRotation($id: ID!) {
    getRotation(id: $id) {
      id
      type
      startDate
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
        }
        nextToken
      }
      cohortsIndex
      published
      createdAt
      updatedAt
    }
  }
`;

export const listRotations = /* GraphQL */ `
  query ListRotations(
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        startDate
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
          }
          nextToken
        }
        cohortsIndex
        published
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;