import { Show, SimpleShowLayout, ReferenceField, NumberField, TextField } from 'react-admin'

export const SeatsShow = (props) => {
    return (
        <Show {...props} >
            <SimpleShowLayout>
                {/* <TextField source="id" /> */}
                <ReferenceField source="rotationID" reference="rotations" label="Rotation" />
                <ReferenceField source="departmentID" reference="departments" label="Department" />
                <NumberField source="preferencePriority" />
            </SimpleShowLayout>
        </Show>)
};