import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    DateField,
    DateInput,
    usePermissions,
    NumberField,
    NumberInput,
    FunctionField,
    SelectInput
} from "react-admin";
import {PostPagination} from './Trainee';


export const RotationsList = (props) => {

    const { permissions } = usePermissions();
    return (
        <List {...props} exporter={false} pagination={<PostPagination/>} filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }}>
            <Datagrid bulkActionButtons={permissions?.includes("Admins") ? true : false} sx={{
                '& .column-startDate': { textAlign: 'left' },
            }} >
                <FunctionField source="startDate" render={record => formatDate(record.startDate)} label="Training Contract Start Date" />
                <FunctionField label="Cohort"render={(record) => {if (record.cohortsIndex <= 2) {return "First year";} else if (record.cohortsIndex <= 4) {return "Second year";} else if (record.cohortsIndex > 4) { return "Qualified"; } }} />
                {permissions?.includes("Admins") && (<EditButton />)}

            </Datagrid>
        </List>
    );
};

export const RotationsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm validate={value => {if (!value.cohortsIndex){return {cohortsIndex: "Need to select a cohort"};} else { return {};}}}>
            <DateInput source="startDate" label="Training Contract Start Date" />
            <SelectInput source="cohortsIndex" label="Current Cohort" choices={[
                { id: '1', name: 'First Year' },
                { id: '3', name: 'Second Year' },
                { id: '5', name: 'Qualified'}
            ]} />
        </SimpleForm>
    </Edit>
);

export const RotationsCreate = (props) => (
    <Create {...props} >
        <SimpleForm defaultValues={{ type: "Rotation" }}>
            <DateInput source="startDate" label="Training Contract Start Date"/>
            <SelectInput source="cohortsIndex" label="Current Cohort" choices={[
    { id: '1', name: 'First Year' },
    { id: '3', name: 'Second Year' },
    { id: '5', name: 'Qualified'}
]} />
        </SimpleForm>
    </Create>
);

export function formatDate(dateString) {
    const dateObject = new Date(dateString)
    const monthYearString = dateObject.toLocaleDateString("en-US", { month: "long", year: "numeric" })
    return monthYearString;
}